module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/macbook/Documents/GitHub/jt-gatsby-blog/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171140784-1","exclude":["/search"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JTK 블로그","short_name":"JTK 블로그","description":"김정태의 블로그입니다.","lang":"ko","start_url":"/","background_color":"white","theme_color":"white","display":"standalone","icon":"contents/images/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f3a6c1050f26c5fc15489e5dd819ed0f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"backgroundColor":"transparent","quality":85,"withWebp":true,"disableBgImage":true,"showCaptions":["alt","title"],"srcSetBreakpoints":[600,728,960,1920]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
